import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/styles.css";
import "../styles/propagabasico.css"
import Cronograma from "../components/cronograma";
import {
  Container,
  Nav,
  Navbar,
  Image,
  Button,
} from "react-bootstrap";
import LogoTransparente from "../assets/logo-transparente.png"
import ImgHd from "../assets/PropagaBáscioHD.jpg"
import ImgLowQlt from "../assets/PropagaBásico.jpg"
const PropagaBasico = () => {
    return (
    <>
        <Navbar
          sticky="top"
          className="navbar-expand-xxxl"
          collapseOnSelect  
          expand="xxxl"
        >
          <Container>
            <Navbar.Brand href="/Home" className="text-white">
              <Image
                style={{
                  width: "70px",
                  marginRight: "10px",
                }}
                src={LogoTransparente}
              />
              SETEPEB
            </Navbar.Brand>
            <Navbar.Toggle className="bg-white"/>
            <Navbar.Collapse className="fw-bolder text-black">
              <Nav className="ml-auto bg-white" id="warpcollapse">
                <Nav.Link href="/Home" className="text-black">Home</Nav.Link>
                <Nav.Link href="/Home#cursos" className="text-black">Cursos</Nav.Link>
                <Nav.Link href="/Home#setepeb" className="text-black">Sede</Nav.Link>
                <Nav.Link href="/Home#sobre" className="text-black">Sobre</Nav.Link>
                <Nav.Link href="/Home#contato" className="text-black">Contato</Nav.Link>
                <Nav.Link
                  className="text-primary fw-bolder"
                  href="http://52.205.173.108:8080/sais/"
                  target="_blank"
                >
                  Acesso
                </Nav.Link>
              </Nav>
            </Navbar.Collapse> 
          </Container>
        </Navbar>
        <Container id="imagecontainer">
          <picture>
          <source media="(max-width: 480px)" srcSet={ImgLowQlt}/>
          <img src={ImgHd} alt=""/>
          </picture>
        </Container>
        <Container>
          <p id="info">OBJETIVO DO CURSO</p>
            <p id="overview">
            Este projeto visa impulsionar nosso anseio por almas e nosso esforço pelo reino, um programa de treinamento de obreiros completo. 
Pensado para atender os anseios de Deus para a Grande Seara, com conhecimento bíblico dinâmico e aprofundado. Desenvolvido para auxiliar novos pregadores professores de escola bíblica e líderes de pequenos grupos, para os capacitar em diversas áreas como: 
           <strong>     
            <ul>
                <li>Ética Pessoal</li>
                <li>Teologia </li>
                <li>Pastoral</li>
                <li>Eclesiástica</li>
                <li>Administrativa</li>
            </ul>
            </strong>
            </p>
        </Container>
        <Container>
          <p id="info">FLEXIBILIDADE DO MODELO ONLINE</p>
          <p>O Seminário Teológico Pentecostal do Brasil utiliza uma plataforma EAD inovadora, oferecendo 20 disciplinas variadas com aulas dinâmicas em um formato moderno e acessível. </p>
        </Container>
        <Container>
          <p id="info">CORPO DOCENTE QUALIFICADO</p>
          <p>O obreiro será desafiado e preparado por professores altamente capacitados. Os novos obreiros cristãos, especialmente da ICPI, serão formados com um material de excelência, incomparável em nível nacional.</p>
        </Container>
        <Container>
          <p id="info">PILARES DO CURSO PROPAGA BASICO</p>
          <p id="pilar">Ao desenvolvermos este treinamento, nos inspiramos no exemplo de Esdras:</p>
          <p id="pilar">
        “Pois Esdras tinha decidido dedicar-se a estudar a Lei do Senhor, a praticá-la e a ensinar os seus decretos e mandamentos aos israelitas.”
        <i>Esdras 7:10</i></p>
        <p id="pilar">Estudar, praticar e ensinar — esse é o nosso propósito com este treinamento. Buscamos capacitar homens e mulheres para que alcancem o máximo de seu potencial no Reino de Deus, tornando-os aptos a aplicar o que aprenderam e a ensinar a outros.</p>
        </Container>
        <Container>
            <p>
            Também lembramos das palavras do Mestre Richard Lawrence.<i>
“Se o aprendizado cristão em essência é discipulado, “um processo de tornar-se como”, então o treinamento para uma vida de serviço deve ser baseado nestes processos. O preparo de uma pessoa para o serviço no Corpo deve ser como o serviço que ela deverá assumir.”</i>

            </p>
        </Container>
        <Container>
        <p id="info">GRADE CURRICULAR</p>
        </Container>
        <Container>
            <Cronograma></Cronograma>
        </Container>
        <Container id="buttoncontainer">
        <Button id="button"
          href="https://propaga.bindemy.com.br/"
          > Inscreva-se aqui</Button>
        </Container>
    </>
    );
};
 
export default PropagaBasico;