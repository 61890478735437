import React from 'react';
import "../styles/propagabasico.css"

function Cronograma() {
  const dados = [
    {
      modulo: 'MÓDULO 1',
      topicos: ['Ética Cristã', 'As Escrituras', 'O Evangelho', 'Vocação', 'Conhecendo a igreja', 'Gestão Pessoal'],
    },
    {
      modulo: 'MÓDULO 2',
      topicos: ['Cosmovisão', 'O Antigo Testamento', 'A igreja e a cidade', 'Ser Pastor', 'Ser Igreja', 'Organização eclesiástica'],
    },
    {
      modulo: 'MÓDULO 3',
      topicos: ['Aconselhamento bíblico', 'Novo Testamento', 'Evangelismo Prático', 'A Cultura do Discipulado'],
    },
    {
      modulo: 'MÓDULO 4',
      topicos: ['Introdução à teologia arminiana', 'Como estudar a Bíblia', 'Mobilização Missionária', 'Prática Pastoral'],
    },
  ];

  return (
    <div className="cronograma">
      {dados.map((modulo, index) => (
        <div key={index} className="modulo">
          <h2>{modulo.modulo}</h2>
          <ul>
            {modulo.topicos.map((topico, index) => (
              <li key={index}>{topico}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Cronograma;