import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React from "react";
import PropagaBasico from './pages/propagabasico';
import Home from './pages/Home';
import ScrollToTop from './components/ScrolltoTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        {/* Redireciona da rota raiz ("/") para "/Home" */}
        <Route path="/" element={<Navigate to="/Home" />} />
        
        {/* Página principal (Home) */}
        <Route path="/Home" element={<Home />} />
        
        {/* Página Propaga Básico */}
        <Route path="/propagabasico" element={<PropagaBasico />} />
      </Routes>
    </Router>
  );
}

export default App;
